import 'stacktable.js';

import MicroModal from 'micromodal';
import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';

const blockMenuOverFlowReachedEnd = (block, wrapper) => {
  const scrollEnd =
    Math.abs(wrapper.scrollLeft) === wrapper.scrollWidth - wrapper.clientWidth;
  if (scrollEnd) {
    if (!block.classList.contains('interlinks-overflow--scroll-end')) {
      block.classList.add('interlinks-overflow--scroll-end');
    }
  } else {
    if (block.classList.contains('interlinks-overflow--scroll-end')) {
      block.classList.remove('interlinks-overflow--scroll-end');
    }
  }
};

function handleSpecificScripts() {

  // Add stacktable class for tables
  const tables = document.querySelectorAll('div.entry-content table');

  tables.forEach((table) => {
    if (!table.closest('[class^="nsn-"]')) {
      table.classList.add('init-stacktable');
    }
  });
  jQuery('.init-stacktable').stacktable();

  const blocks = document.querySelectorAll('.row-interlinks');
  if (blocks.length) {
    blocks.forEach((block) => {
      const menu = block.querySelector('menu');
      if (menu) {
        // Check if the menu has scrolled all he way to the end
        menu.addEventListener('scroll', () => {
          blockMenuOverFlowReachedEnd(block, menu);
        });

        setTimeout(() => {
          blockMenuOverFlowReachedEnd(block, menu);
        }, 0);

        block.classList.add('interlinks-overflow');
      }
    });
  }

  const modalNotRecommended = document.querySelector('#modal-not-recommended');
  if (modalNotRecommended) {
    // Prevent escape key from closing the modal
    // Must be done before MicroModal.init()
    document.addEventListener('keydown', (e) => {
      if (e.key === 'Escape' && modalNotRecommended.classList.contains('is-open')) {
        e.stopImmediatePropagation()
        return false;
      }
    });
    
    MicroModal.init();
    MicroModal.show('modal-not-recommended', {
      disableScroll: true,
      closeTrigger: '',
    });

    const modalContent = modalNotRecommended.querySelector(
      '.not-recommended-coupons',
    );

    if (modalContent) {
      const modalContentHeight = modalContent.offsetHeight;
      const headerHeight = document.querySelector('#header').offsetHeight;

      if (modalContentHeight > window.innerHeight - headerHeight * 2) {
        modalNotRecommended.classList.add('oversize');
      }
    }
  }

  /* eslint-disable no-unused-vars */
  if (window.matchMedia('(max-width: 767px)').matches) {
    const swipernotrecommended = new Swiper(
      '.not-recommended-coupons.multiple-coupons .coupons',
      {
        modules: [Navigation],
        slidesPerView: 1,
        spaceBetween: 24,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        on: {
          reachEnd: function () {
            const swiperCoupons = document.querySelectorAll(
              '.not-recommended-coupons.multiple-coupons .coupons',
            );
            if (swiperCoupons) {
              swiperCoupons.forEach((swiperCoupon) => {
                swiperCoupon.classList.add('reached-end');
              });
            }
          },
          reachBeginning: function () {
            const swiperCoupons = document.querySelectorAll(
              '.not-recommended-coupons.multiple-coupons .coupons',
            );
            if (swiperCoupons) {
              swiperCoupons.forEach((swiperCoupon) => {
                swiperCoupon.classList.add('reached-beginning');
              });
            }
          },
          slideChange: function () {
            if (!this.isEnd) {
              const swiperCoupons = document.querySelectorAll(
                '.not-recommended-coupons.multiple-coupons .coupons',
              );
              if (swiperCoupons) {
                swiperCoupons.forEach((swiperCoupon) => {
                  swiperCoupon.classList.remove('reached-end');
                });
              }
            }
            if (!this.isBeginning) {
              const swiperCoupons = document.querySelectorAll(
                '.not-recommended-coupons.multiple-coupons .coupons',
              );
              if (swiperCoupons) {
                swiperCoupons.forEach((swiperCoupon) => {
                  swiperCoupon.classList.remove('reached-beginning');
                });
              }
            }
          },
        },
      },
    );
  } else {
    const swiperbtnsPrev = document.querySelectorAll(
      '.not-recommended-coupons .coupons .swiper-button-prev',
    );
    if (swiperbtnsPrev) {
      swiperbtnsPrev.forEach((swiperPrev) => {
        swiperPrev.remove();
      });
    }
    const swiperbtnsNext = document.querySelectorAll(
      '.not-recommended-coupons .coupons .swiper-button-next',
    );
    if (swiperbtnsNext) {
      swiperbtnsNext.forEach((swiperNext) => {
        swiperNext.remove();
      });
    }

    const fragment = document.createDocumentFragment();
    const elements = document.querySelectorAll(
      '.not-recommended-coupons .coupons .swiper-wrapper',
    );
    if (elements) {
      elements.forEach((element) => {
        while (element.firstChild) {
          fragment.appendChild(element.firstChild);
        }
        element.parentNode.replaceChild(fragment, element);
      });
    }
  }
  /* eslint-enable no-unused-vars */
}

if (document.readyState !== 'loading') {
  handleSpecificScripts();
} else {
  document.addEventListener('DOMContentLoaded', handleSpecificScripts);
}
